import React, { useEffect, useRef } from 'react';

export const About = (props) => {
  const aboutRef = useRef(null);
  const countersRef = useRef([]);

  useEffect(() => {
    const aboutItems = aboutRef.current.querySelectorAll('.fade-in-left, .fade-in-right');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        root: null, // viewport browser
        rootMargin: '0px', // default margin
        threshold: 0.5 // 50% elemen harus terlihat untuk memicu animasi
      }
    );

    aboutItems.forEach((item) => {
      observer.observe(item);
    });

    return () => {
      aboutItems.forEach((item) => {
        observer.unobserve(item);
      });
    };
  }, []);

  useEffect(() => {
    const handleCounter = (element, endValue) => {
      let startValue = 0;
      const duration = 10000; // Increase duration to make counting slower
      const increment = endValue / (duration / 50); // Adjust increment based on new duration
  
      const countUp = () => {
        startValue += increment;
        if (startValue >= endValue) {
          element.textContent = endValue;
        } else {
          element.textContent = Math.ceil(startValue);
          requestAnimationFrame(countUp);
        }
      };
  
      countUp();
    };
  
    const counterObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            countersRef.current.forEach((counter, index) => {
              const endValue = [23, 29, 16][index]; // Specify the final values here
              handleCounter(counter, endValue);
            });
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      }
    );
  
    const counterSection = document.querySelector('.counters-section');
    counterObserver.observe(counterSection);
  
    return () => {
      counterObserver.disconnect();
    };
  }, []);
  

  return (
    <div id="about" ref={aboutRef}>
      {/* First section */}
      <div className="container mt-100">
        <div className="row">
          <div className="col-xs-12">
            <div className="row">
              {/* Left Text Section with fade-in-left */}
              <div className="col-xs-12 col-md-6 fade-in-left">
                <div className="about-text">
                  <h2>
                    Get a <span style={{ color: 'orange' }}>Security Printing</span> Service that{' '}
                    <span style={{ color: '#00c4cc' }}>Suits You!</span>
                  </h2>
                  <p>
                    At Aspersindo, we believe that the security product is the little
                    things that give us a more original and secure feeling of
                    ourselves, makes us more trust and comfort.
                  </p>
                </div>
              </div>

              {/* Right Image Section with fade-in-right */}
              <div className="col-xs-12 col-md-6 fade-in-right">
                <img
                  src="img/about-1.svg"
                  className="img-responsive"
                  alt="About Aspersindo"
                />
              </div>
            </div>

            {/* Second Row */}
            <div className="row mt-about about-section">
              {/* Image Section with fade-in-left */}
              <div className="col-xs-12 col-md-6 fade-in-left">
                <img
                  src="img/about-2.svg"
                  className="img-responsive"
                  alt="History of Aspersindo"
                />
              </div>

              {/* Right Text Section with fade-in-right */}
              <div className="col-xs-12 col-md-6 mt-text fade-in-right">
                <div className="about-text">
                  <h2>
                    History of <span style={{ color: 'orange' }}>ASPERSINDO</span>
                  </h2>
                  <p>
                    The Indonesian Security Document Printing Association (ASPERSINDO) was
                    established on November 17, 2000. The security printing industry needs
                    a forum to enable the implementation of such guidance and development
                    in welcoming the free market and globalization era. <br /> <br />
                    Now more than 30+ companies have joined ASPERSINDO.
                  </p>
                </div>
              </div>
            </div>

            {/* Third Row */}
            <div className="row mt-about">
              {/* Left Text Section with fade-in-left */}
              <div className="col-xs-12 col-md-6 fade-in-left">
                <div className="about-text">
                  <h2 style={{ color: '#00c4cc' }}>
                    Our Vision <span style={{ color: '2F4858' }}>And</span>{' '}
                    <span style={{ color: 'orange' }}>Mission</span>
                  </h2>
                  <h3>Our Vision</h3>
                  <p>
                    Synergize security printing companies in Indonesia within a
                    framework of fair competition to become a trusted partner for
                    security document solutions.
                  </p>
                </div>
              </div>

              {/* Right Image Section with fade-in-right */}
              <div className="col-xs-12 col-md-6 fade-in-right">
                <img
                  src="img/about-3.svg"
                  className="img-responsive"
                  alt="About Aspersindo"
                />
              </div>
            </div>

            {/* Mission Section */}
            <div className="row mt-about fade-in-left">
              <div className="col-xs-12">
                <h3 style={{ paddingLeft: '35px' }}>Our Mission</h3>
                <div className="col-xs-12 mt-about">
                  <ol style={{ paddingLeft: '20px' }}>
                    <li style={{ marginBottom: '10px', marginTop: '10px' }}>
                      1) Assist the Government and the public who use security document
                      products to prevent attempts to counterfeit security documents.
                    </li>
                    <li style={{ marginBottom: '10px', marginTop: '10px' }}>
                      2) Help develop a healthy and mutually beneficial business climate for
                      every Indonesian security printing company.
                    </li>
                    <li style={{ marginBottom: '10px', marginTop: '10px' }}>
                      3) Fostering and developing security technology capabilities for the
                      benefit of Indonesian security document printing companies,
                      including security document service users.
                    </li>
                    <li style={{ marginBottom: '10px', marginTop: '10px' }}>
                      4) Participate in developing security printing in Indonesia to play a
                      role in the global market.
                    </li>
                    <li style={{ marginBottom: '10px', marginTop: '10px' }}>
                      5) Fostering and developing a sense of togetherness between members.
                    </li>
                    <li style={{ marginBottom: '10px', marginTop: '10px' }}>
                      6) Preventing unfair competition and resolving disputes between members
                      based on the code of ethics.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
             {/* New Counter Section */}
            <div className="counters-section">
              <h2>Keep Giving Our Benefits</h2>
              <div className="row counters-content">
                <div className="col-xs-12 col-md-4 counter-item">
                  <h3 ref={(el) => (countersRef.current[0] = el)}>0</h3>
                  <p>years of Service</p>
                </div>
                <div className="col-xs-12 col-md-4 counter-item">
                  <h3 ref={(el) => (countersRef.current[1] = el)}>0</h3>
                  <p>Companies members</p>
                </div>
                <div className="col-xs-12 col-md-4 counter-item">
                  <h3 ref={(el) => (countersRef.current[2] = el)}>0</h3>
                  <p>Cities Company Location</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
