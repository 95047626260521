import React, { useEffect, useRef } from 'react';

export const Gallery = () => {
  const galleryRef = useRef(null);

  useEffect(() => {
    const galleryItems = galleryRef.current.querySelectorAll('.gallery-item, .video-container, .additional-images img, .gallery-title');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, {
      root: null, // viewport browser
      rootMargin: '0px', // default margin
      threshold: 0.5 // 50% elemen harus terlihat untuk memicu animasi
    });

    galleryItems.forEach((item) => {
      observer.observe(item);
    });

    return () => {
      galleryItems.forEach((item) => {
        observer.unobserve(item);
      });
    };
  }, []);

  return (
    <div id="gallery" className="gallery-container"  ref={galleryRef}>
      <h1 className="gallery-title fade-in-left">Labuan Bajo</h1>
      <div className="gallery-grid labuan-bajo">
        <div className="gallery-item item1 fade-in">
          <img src="/img/Laboan-bajo/galery-1.jpeg" alt="Group photo at night" />
        </div>
        <div className="gallery-item item2 fade-in">
          <img src="/img/Laboan-bajo/galery-2.jpeg" alt="Panel discussion" />
        </div>
        <div className="gallery-item item3 fade-in">
          <img src="/img/Laboan-bajo/galery-3.jpeg" alt="Conference attendees" />
        </div>
        <div className="gallery-item item4 fade-in">
          <img src="/img/Laboan-bajo/galery-4.jpeg" alt="Large group photo" />
        </div>
        <div className="gallery-item item5 fade-in">
          <img src="/img/Laboan-bajo/galery-5.jpeg" alt="Group photo with raised hands" />
        </div>
        <div className="gallery-item item6 fade-in">
          <img src="/img/Laboan-bajo/galery-6.jpeg" alt="Statue display" />
        </div>
      </div>

      <div className="video-container fade-in">
        <video controls width="100%">
          <source src="/img/Laboan-bajo/vidio.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <h1 className="gallery-title fade-in-left">Pelantikan Pengurus Masa Kahar</h1>
      <div className="gallery-grid ppmk">
        <div className="gallery-item ppmk1 fade-in">
          <img src="/img/PPMK/galery-1.jpeg" alt="Pelantikan Pengurus 1" />
        </div>
        <div className="gallery-item ppmk2 fade-in">
          <img src="/img/PPMK/galery-2.jpeg" alt="Pelantikan Pengurus 2" />
        </div>
        <div className="gallery-item ppmk3 fade-in">
          <img src="/img/PPMK/galery-3.jpeg" alt="Pelantikan Pengurus 3" />
        </div>
        <div className="gallery-item ppmk4 fade-in">
          <img src="/img/PPMK/galery-4.jpeg" alt="Pelantikan Pengurus 4" />
        </div>
        <div className="gallery-item ppmk5 fade-in">
          <img src="/img/PPMK/galery-5.jpeg" alt="Pelantikan Pengurus 5" />
        </div>
      </div>
    </div>
  );
};
